import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Concrete = () => (
  <Layout>
    <SEO
      title="Concrete"
      description="We offer a wide range of concrete solutions, designed to
      meet your specific project requirements. You have an
      extensive selection of colours, textures and finishes to
      choose from."
      image={"/concrete.jpg"}
    />
    <section className="p-0">
      <div className="row g-0">
        <div className="col-lg-6 d-flex align-items-center justify-content-center">
          <div className="content-box">
            <h1 className="section-heading section-heading--left mb-lg-5">
              Concrete
            </h1>
            <p>
              We offer a wide range of concrete solutions, designed to meet your
              specific project requirements.
            </p>
            <ul>
              <li>Pavement Construction</li>
              <li>Footpaths</li>
              <li>Kerb & Channel</li>
              <li>Dish Channel</li>
              <li>Nib Kerbing</li>
              <li>Vehicle Crossings</li>
              <li>Driveways</li>
              <li>Garage Slabs</li>
              <li>Patio Areas</li>
              <li>Concrete Formwork</li>
            </ul>

            <p>
              We offer an extensive selection of colours, textures and finishes
              for you to choose from.
            </p>
            <ul>
              <li>Coloured Concrete</li>
              <li>Broom Finish</li>
              <li>Exposed Aggregate</li>
            </ul>
            <p>
              <Link className="btn btn-primary mt-4" to="/contact/">
                Free quote
              </Link>
            </p>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-center">
          {/* <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/concrete.jpg"
                  alt="concrete path"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel> */}
          <StaticImage
            aspectRatio={12 / 12}
            src="../images/concrete.jpg"
            alt="concrete path"
            loading="eager"
          />
        </div>
      </div>
    </section>
  </Layout>
);

export default Concrete;
